import React, { Component } from "react";

/**
 * une carte
 */

function Card({name, path, gender, tags, des}) {
    return (
        <div className="card col-lg-4 col-md-6 col-sm-12">
            <div className="nom"><p>{name}</p></div>
            <img alt={name} className="image" src={path}/>
            <div className="texte">
                <h4>{gender}</h4>
                <h5>
                    {tags.map(tag => (
                        <a href="#titre">#{tag}</a>
                    ))}
                </h5>
                <p>{des}</p>
            </div>
        </div>
     );
}

export {Card};