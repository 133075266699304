import { Component,Fragment } from 'react';
import {Card} from './Card'
import './App.css';

/**
 * Componant principal
 */

let check_check = {
    nom : true,
    gender : true,
    des : true,
    tags : true
}

let foo = (s) => {
    if (s === "check-name") {
        return ".nom"
    }
    else if(s === "check-gender"){
        return ".texte h4"
    }
    else if(s === "check-tags"){
        return ".texte h5"
    }
    else if(s === "check-description"){
        return ".texte p"
    }
    else {
        return "null"
    }
}

let set_check = (s,v,o)  => {
    if (s === "check-name") {
        o.nom = v
    }
    else if(s === "check-gender"){
        o.gender = v
    }
    else if(s === "check-description"){
        o.des = v
    }
    else if(s === "check-tags"){
        o.tags = v
    }
    else {
        console.log("something went wrong")
    }
    return o
}

let page = 1

class App extends Component {
    constructor() {
        super()
        this.state = {
            check_check: check_check,
            data: null
        }
    }

    getData = async () => {
        let url = 'https://fastapi-production-d770.up.railway.app/animals/'
        url = url.concat(page.toString())
        const response = await fetch(url)
        const data = await response.json()
        return data
    }
    
    getAnimals = () => {
        console.log(this.state.data)
        this.getData().then(data1 => {
            this.setState({
                data: data1
            })
        })
    }

    handle_page = (evt) =>{
        let id = evt.target.id
        if (id === "previous" && page === 1) {
            page = 1
        } else if(id === "previous"){
            page--
        } else{
            page++
        }
        this.getAnimals()
    }

    handle_change = (event) => {
        const st = {...this.state.check_check}
        let ch = set_check(event.target.id, event.target.checked, st)
        let arr = document.querySelectorAll(foo(event.target.id))
        if(event.target.checked){
            for (let i = 0; i < arr.length; i++) {
                arr[i].style.display = "block"
                document.getElementById(event.target.id).checked = true
            }
        }
        else{
            for (let i = 0; i < arr.length; i++) {
                arr[i].style.display = "none"
                document.getElementById(event.target.id).checked = false

            }
        }
        this.setState({
            check_check: ch
        })
    }

    componentWillMount() {
        this.getAnimals()
    } 

  
  render() { 

    const {check_check} = this.state

    return (
      <Fragment>
          <h1 id="titre">Petfinder API Data</h1>
          <div className="container-fluid">
              <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-12 container-left">
                      <h3>Check what to display</h3>
                      <div className="check-btn">
                          <input type="checkbox" id="check-name"  value={check_check.nom}  onChange={this.handle_change}
                          />
                          <label >Name</label>
                      </div>
                      <div className="check-btn">
                          <input type="checkbox" id="check-gender" value={check_check.gender}  onChange={this.handle_change}/>
                          <label >Gender</label>
                      </div>
                      <div className="check-btn">
                          <input type="checkbox" id="check-description" value={check_check.des}  onChange={this.handle_change}/>
                          <label >Description</label>
                      </div>
                      <div className="check-btn">
                          <input type="checkbox" id="check-tags" value={check_check.tags}  onChange={this.handle_change}/>
                          <label >Tags</label>
                      </div>
                  </div>
                  <div className="col-lg-9 col-md-12 col-sm-12 container-right">
                      <div className="container" id="animals-container">

                            <div className="row">                        
                                {
                                (this.state.data != null) ?
                                (this.state.data.animals.map(
                                    animal =>(
                                        <Card name={animal.name} 
                                        path={(animal.photos.length === 0)? "https://www.pngitem.com/pimgs/m/255-2550411_404-error-images-free-png-transparent-png.png" : animal.photos[0].full
                                        } 
                                        gender={animal.gender} 
                                        tags={ (animal.tags.length === 0)? ["no", "tags", "found"] : animal.tags
                                        } 
                                        des={animal.description}/>
                                        )
                                    )
                                ) : <p></p>          
                                }
                        
                            </div>

                      </div>
                      
                  </div>
              </div>
          </div>
          <div className="boutons">
                <a href="#titre"><button id="previous" onClick={this.handle_page}>Prev.</button></a>
                <a href="#titre"><button id="next" onClick={this.handle_page}>Next</button></a>
          </div>
      </Fragment>
    );
  }
}
 
export default App;

